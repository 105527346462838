

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en-US","file":"en.json"},{"code":"tr","iso":"tr-TR","file":"tr.json"},{"code":"fr","iso":"fr-FR","file":"fr.json"},{"code":"de","iso":"de-DE","file":"de.json"},{"code":"it","iso":"it-IT","file":"it.json"},{"code":"pt","iso":"pt-PT","file":"pt.json"},{"code":"he","iso":"he-IL","file":"he.json"},{"code":"ar","iso":"ar-SA","file":"ar.json"},{"code":"es","iso":"es-ES","file":"es.json"},{"code":"zh","iso":"zh-CN","file":"zh.json"},{"code":"ja","iso":"ja-JP","file":"ja.json"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/var/www/html/bigpoll-ui/locales",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":true,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"en","onlyOnNoPrefix":false,"onlyOnRoot":false,"useCookie":true},
  differentDomains: false,
  seo: true,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","iso":"en-US","file":"en.json"},{"code":"tr","iso":"tr-TR","file":"tr.json"},{"code":"fr","iso":"fr-FR","file":"fr.json"},{"code":"de","iso":"de-DE","file":"de.json"},{"code":"it","iso":"it-IT","file":"it.json"},{"code":"pt","iso":"pt-PT","file":"pt.json"},{"code":"he","iso":"he-IL","file":"he.json"},{"code":"ar","iso":"ar-SA","file":"ar.json"},{"code":"es","iso":"es-ES","file":"es.json"},{"code":"zh","iso":"zh-CN","file":"zh.json"},{"code":"ja","iso":"ja-JP","file":"ja.json"}],
  localeCodes: ["en","tr","fr","de","it","pt","he","ar","es","zh","ja"],
}

export const localeMessages = {
  'en.json': () => import('../../locales/en.json' /* webpackChunkName: "lang-en.json" */),
  'tr.json': () => import('../../locales/tr.json' /* webpackChunkName: "lang-tr.json" */),
  'fr.json': () => import('../../locales/fr.json' /* webpackChunkName: "lang-fr.json" */),
  'de.json': () => import('../../locales/de.json' /* webpackChunkName: "lang-de.json" */),
  'it.json': () => import('../../locales/it.json' /* webpackChunkName: "lang-it.json" */),
  'pt.json': () => import('../../locales/pt.json' /* webpackChunkName: "lang-pt.json" */),
  'he.json': () => import('../../locales/he.json' /* webpackChunkName: "lang-he.json" */),
  'ar.json': () => import('../../locales/ar.json' /* webpackChunkName: "lang-ar.json" */),
  'es.json': () => import('../../locales/es.json' /* webpackChunkName: "lang-es.json" */),
  'zh.json': () => import('../../locales/zh.json' /* webpackChunkName: "lang-zh.json" */),
  'ja.json': () => import('../../locales/ja.json' /* webpackChunkName: "lang-ja.json" */),
}
