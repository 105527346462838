//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapGetters
} from 'vuex'

export default {
  components: {},

  data() {
    return {
      announcementShow: true,
      mobileMenuShow: false,
      cookieShow: true,
    };
  },
  methods: {
    async logout() {
      await this.$auth.logout();
    },
    acceptCookieConsent() {
      this.cookieShow = false;
      this.$cookies.set("cookie-accept", 1, {
        path: "/",
        maxAge: 60 * 60 * 24 * 365,
      });
    },
  },
  computed: {
    ...mapGetters(['loggedInUser', 'isAuthenticated'])
  }
};

