import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _fd3b8c50 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _bdcbd6c6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _ac466c7e = () => interopDefault(import('../pages/create.vue' /* webpackChunkName: "pages/create" */))
const _e34709be = () => interopDefault(import('../pages/health.vue' /* webpackChunkName: "pages/health" */))
const _6ac1b7b4 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _6c90c942 = () => interopDefault(import('../pages/polls.vue' /* webpackChunkName: "pages/polls" */))
const _0b640393 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _87411d98 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _39cf2bdd = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _4b17d2d2 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _1d6d9908 = () => interopDefault(import('../pages/_id/index.vue' /* webpackChunkName: "pages/_id/index" */))
const _728d9fa8 = () => interopDefault(import('../pages/_id/r.vue' /* webpackChunkName: "pages/_id/r" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _fd3b8c50,
    name: "about___en"
  }, {
    path: "/ar",
    component: _bdcbd6c6,
    name: "index___ar"
  }, {
    path: "/create",
    component: _ac466c7e,
    name: "create___en"
  }, {
    path: "/de",
    component: _bdcbd6c6,
    name: "index___de"
  }, {
    path: "/es",
    component: _bdcbd6c6,
    name: "index___es"
  }, {
    path: "/fr",
    component: _bdcbd6c6,
    name: "index___fr"
  }, {
    path: "/he",
    component: _bdcbd6c6,
    name: "index___he"
  }, {
    path: "/health",
    component: _e34709be,
    name: "health___en"
  }, {
    path: "/it",
    component: _bdcbd6c6,
    name: "index___it"
  }, {
    path: "/ja",
    component: _bdcbd6c6,
    name: "index___ja"
  }, {
    path: "/login",
    component: _6ac1b7b4,
    name: "login___en"
  }, {
    path: "/polls",
    component: _6c90c942,
    name: "polls___en"
  }, {
    path: "/privacy",
    component: _0b640393,
    name: "privacy___en"
  }, {
    path: "/profile",
    component: _87411d98,
    name: "profile___en"
  }, {
    path: "/pt",
    component: _bdcbd6c6,
    name: "index___pt"
  }, {
    path: "/signup",
    component: _39cf2bdd,
    name: "signup___en"
  }, {
    path: "/terms",
    component: _4b17d2d2,
    name: "terms___en"
  }, {
    path: "/tr",
    component: _bdcbd6c6,
    name: "index___tr"
  }, {
    path: "/zh",
    component: _bdcbd6c6,
    name: "index___zh"
  }, {
    path: "/ar/about",
    component: _fd3b8c50,
    name: "about___ar"
  }, {
    path: "/ar/create",
    component: _ac466c7e,
    name: "create___ar"
  }, {
    path: "/ar/health",
    component: _e34709be,
    name: "health___ar"
  }, {
    path: "/ar/login",
    component: _6ac1b7b4,
    name: "login___ar"
  }, {
    path: "/ar/polls",
    component: _6c90c942,
    name: "polls___ar"
  }, {
    path: "/ar/privacy",
    component: _0b640393,
    name: "privacy___ar"
  }, {
    path: "/ar/profile",
    component: _87411d98,
    name: "profile___ar"
  }, {
    path: "/ar/signup",
    component: _39cf2bdd,
    name: "signup___ar"
  }, {
    path: "/ar/terms",
    component: _4b17d2d2,
    name: "terms___ar"
  }, {
    path: "/de/about",
    component: _fd3b8c50,
    name: "about___de"
  }, {
    path: "/de/create",
    component: _ac466c7e,
    name: "create___de"
  }, {
    path: "/de/health",
    component: _e34709be,
    name: "health___de"
  }, {
    path: "/de/login",
    component: _6ac1b7b4,
    name: "login___de"
  }, {
    path: "/de/polls",
    component: _6c90c942,
    name: "polls___de"
  }, {
    path: "/de/privacy",
    component: _0b640393,
    name: "privacy___de"
  }, {
    path: "/de/profile",
    component: _87411d98,
    name: "profile___de"
  }, {
    path: "/de/signup",
    component: _39cf2bdd,
    name: "signup___de"
  }, {
    path: "/de/terms",
    component: _4b17d2d2,
    name: "terms___de"
  }, {
    path: "/es/about",
    component: _fd3b8c50,
    name: "about___es"
  }, {
    path: "/es/create",
    component: _ac466c7e,
    name: "create___es"
  }, {
    path: "/es/health",
    component: _e34709be,
    name: "health___es"
  }, {
    path: "/es/login",
    component: _6ac1b7b4,
    name: "login___es"
  }, {
    path: "/es/polls",
    component: _6c90c942,
    name: "polls___es"
  }, {
    path: "/es/privacy",
    component: _0b640393,
    name: "privacy___es"
  }, {
    path: "/es/profile",
    component: _87411d98,
    name: "profile___es"
  }, {
    path: "/es/signup",
    component: _39cf2bdd,
    name: "signup___es"
  }, {
    path: "/es/terms",
    component: _4b17d2d2,
    name: "terms___es"
  }, {
    path: "/fr/about",
    component: _fd3b8c50,
    name: "about___fr"
  }, {
    path: "/fr/create",
    component: _ac466c7e,
    name: "create___fr"
  }, {
    path: "/fr/health",
    component: _e34709be,
    name: "health___fr"
  }, {
    path: "/fr/login",
    component: _6ac1b7b4,
    name: "login___fr"
  }, {
    path: "/fr/polls",
    component: _6c90c942,
    name: "polls___fr"
  }, {
    path: "/fr/privacy",
    component: _0b640393,
    name: "privacy___fr"
  }, {
    path: "/fr/profile",
    component: _87411d98,
    name: "profile___fr"
  }, {
    path: "/fr/signup",
    component: _39cf2bdd,
    name: "signup___fr"
  }, {
    path: "/fr/terms",
    component: _4b17d2d2,
    name: "terms___fr"
  }, {
    path: "/he/about",
    component: _fd3b8c50,
    name: "about___he"
  }, {
    path: "/he/create",
    component: _ac466c7e,
    name: "create___he"
  }, {
    path: "/he/health",
    component: _e34709be,
    name: "health___he"
  }, {
    path: "/he/login",
    component: _6ac1b7b4,
    name: "login___he"
  }, {
    path: "/he/polls",
    component: _6c90c942,
    name: "polls___he"
  }, {
    path: "/he/privacy",
    component: _0b640393,
    name: "privacy___he"
  }, {
    path: "/he/profile",
    component: _87411d98,
    name: "profile___he"
  }, {
    path: "/he/signup",
    component: _39cf2bdd,
    name: "signup___he"
  }, {
    path: "/he/terms",
    component: _4b17d2d2,
    name: "terms___he"
  }, {
    path: "/it/about",
    component: _fd3b8c50,
    name: "about___it"
  }, {
    path: "/it/create",
    component: _ac466c7e,
    name: "create___it"
  }, {
    path: "/it/health",
    component: _e34709be,
    name: "health___it"
  }, {
    path: "/it/login",
    component: _6ac1b7b4,
    name: "login___it"
  }, {
    path: "/it/polls",
    component: _6c90c942,
    name: "polls___it"
  }, {
    path: "/it/privacy",
    component: _0b640393,
    name: "privacy___it"
  }, {
    path: "/it/profile",
    component: _87411d98,
    name: "profile___it"
  }, {
    path: "/it/signup",
    component: _39cf2bdd,
    name: "signup___it"
  }, {
    path: "/it/terms",
    component: _4b17d2d2,
    name: "terms___it"
  }, {
    path: "/ja/about",
    component: _fd3b8c50,
    name: "about___ja"
  }, {
    path: "/ja/create",
    component: _ac466c7e,
    name: "create___ja"
  }, {
    path: "/ja/health",
    component: _e34709be,
    name: "health___ja"
  }, {
    path: "/ja/login",
    component: _6ac1b7b4,
    name: "login___ja"
  }, {
    path: "/ja/polls",
    component: _6c90c942,
    name: "polls___ja"
  }, {
    path: "/ja/privacy",
    component: _0b640393,
    name: "privacy___ja"
  }, {
    path: "/ja/profile",
    component: _87411d98,
    name: "profile___ja"
  }, {
    path: "/ja/signup",
    component: _39cf2bdd,
    name: "signup___ja"
  }, {
    path: "/ja/terms",
    component: _4b17d2d2,
    name: "terms___ja"
  }, {
    path: "/pt/about",
    component: _fd3b8c50,
    name: "about___pt"
  }, {
    path: "/pt/create",
    component: _ac466c7e,
    name: "create___pt"
  }, {
    path: "/pt/health",
    component: _e34709be,
    name: "health___pt"
  }, {
    path: "/pt/login",
    component: _6ac1b7b4,
    name: "login___pt"
  }, {
    path: "/pt/polls",
    component: _6c90c942,
    name: "polls___pt"
  }, {
    path: "/pt/privacy",
    component: _0b640393,
    name: "privacy___pt"
  }, {
    path: "/pt/profile",
    component: _87411d98,
    name: "profile___pt"
  }, {
    path: "/pt/signup",
    component: _39cf2bdd,
    name: "signup___pt"
  }, {
    path: "/pt/terms",
    component: _4b17d2d2,
    name: "terms___pt"
  }, {
    path: "/tr/about",
    component: _fd3b8c50,
    name: "about___tr"
  }, {
    path: "/tr/create",
    component: _ac466c7e,
    name: "create___tr"
  }, {
    path: "/tr/health",
    component: _e34709be,
    name: "health___tr"
  }, {
    path: "/tr/login",
    component: _6ac1b7b4,
    name: "login___tr"
  }, {
    path: "/tr/polls",
    component: _6c90c942,
    name: "polls___tr"
  }, {
    path: "/tr/privacy",
    component: _0b640393,
    name: "privacy___tr"
  }, {
    path: "/tr/profile",
    component: _87411d98,
    name: "profile___tr"
  }, {
    path: "/tr/signup",
    component: _39cf2bdd,
    name: "signup___tr"
  }, {
    path: "/tr/terms",
    component: _4b17d2d2,
    name: "terms___tr"
  }, {
    path: "/zh/about",
    component: _fd3b8c50,
    name: "about___zh"
  }, {
    path: "/zh/create",
    component: _ac466c7e,
    name: "create___zh"
  }, {
    path: "/zh/health",
    component: _e34709be,
    name: "health___zh"
  }, {
    path: "/zh/login",
    component: _6ac1b7b4,
    name: "login___zh"
  }, {
    path: "/zh/polls",
    component: _6c90c942,
    name: "polls___zh"
  }, {
    path: "/zh/privacy",
    component: _0b640393,
    name: "privacy___zh"
  }, {
    path: "/zh/profile",
    component: _87411d98,
    name: "profile___zh"
  }, {
    path: "/zh/signup",
    component: _39cf2bdd,
    name: "signup___zh"
  }, {
    path: "/zh/terms",
    component: _4b17d2d2,
    name: "terms___zh"
  }, {
    path: "/ar/:id",
    component: _1d6d9908,
    name: "id___ar"
  }, {
    path: "/de/:id",
    component: _1d6d9908,
    name: "id___de"
  }, {
    path: "/es/:id",
    component: _1d6d9908,
    name: "id___es"
  }, {
    path: "/fr/:id",
    component: _1d6d9908,
    name: "id___fr"
  }, {
    path: "/he/:id",
    component: _1d6d9908,
    name: "id___he"
  }, {
    path: "/it/:id",
    component: _1d6d9908,
    name: "id___it"
  }, {
    path: "/ja/:id",
    component: _1d6d9908,
    name: "id___ja"
  }, {
    path: "/pt/:id",
    component: _1d6d9908,
    name: "id___pt"
  }, {
    path: "/tr/:id",
    component: _1d6d9908,
    name: "id___tr"
  }, {
    path: "/zh/:id",
    component: _1d6d9908,
    name: "id___zh"
  }, {
    path: "/ar/:id/r",
    component: _728d9fa8,
    name: "id-r___ar"
  }, {
    path: "/de/:id/r",
    component: _728d9fa8,
    name: "id-r___de"
  }, {
    path: "/es/:id/r",
    component: _728d9fa8,
    name: "id-r___es"
  }, {
    path: "/fr/:id/r",
    component: _728d9fa8,
    name: "id-r___fr"
  }, {
    path: "/he/:id/r",
    component: _728d9fa8,
    name: "id-r___he"
  }, {
    path: "/it/:id/r",
    component: _728d9fa8,
    name: "id-r___it"
  }, {
    path: "/ja/:id/r",
    component: _728d9fa8,
    name: "id-r___ja"
  }, {
    path: "/pt/:id/r",
    component: _728d9fa8,
    name: "id-r___pt"
  }, {
    path: "/tr/:id/r",
    component: _728d9fa8,
    name: "id-r___tr"
  }, {
    path: "/zh/:id/r",
    component: _728d9fa8,
    name: "id-r___zh"
  }, {
    path: "/",
    component: _bdcbd6c6,
    name: "index___en"
  }, {
    path: "/:id",
    component: _1d6d9908,
    name: "id___en"
  }, {
    path: "/:id/r",
    component: _728d9fa8,
    name: "id-r___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
